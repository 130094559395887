import Head from 'next/head';
import Header from "./inc/header";
import Footer from "./inc/footer";
import Script from 'next/script'

export default function Layout({ children, title }) {
	
	return (
		<>
			<Head>
				<meta charset="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{ title &&
					<title>{title}</title>
				}
			</Head>
			
			<div className="body-wrap">
				<Header />
				
				<div className="md:w-3/5 lg:w-[50rem] mx-auto">
					{children}
				</div>
				
				<Footer />
			</div>
			<Script
				src="https://code.jquery.com/jquery-3.5.1.min.js"
				integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
				crossOrigin="anonymous"></Script>
		</>
	);
}
